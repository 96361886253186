import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { Article, Category } from "../../types";
import { Categories } from "./Categories";
import { SearchInput } from "./SearchInput";

export const SearchBar = () => {
  const [latestArticles, setLatestArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const { language } = useI18next();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await sendRequestToAPI(
          `query($locale:I18NLocaleCode ){
              newsCategories(locale: $locale, filters:{articles:{slug: {notNull: true}}}, sort:"categoryName:asc"){
                  data{
                    attributes{
                      categoryId
                      categoryName
                    }
                  }
                }
              articles(locale: $locale, pagination:{limit: 3}, sort:"publicationDate:desc"){  
              meta{pagination{
              total
              page
              pageSize
              pageCount}}
              data{attributes{
              publicationDate
              description
              title
              slug
              previewImage{
                data{attributes{url}}
              }
              image{
                data{attributes{url}}
              }
              category{
                data{attributes{categoryName
                categoryId}}
              }
            }}
          }
        }`,
          { locale: language }
        );
        setLatestArticles(data.data.articles.data);
        setCategories(data.data.newsCategories.data);
      } catch (err) {
        return err;
      }
    };
    getData();
  }, []);

  return (
    <SearchBarWrap>
      <SearchInput type="gray" />
      <Categories latestArticles={latestArticles} categories={categories} />
    </SearchBarWrap>
  );
};

const SearchBarWrap = styled("div", {
  display: "flex",
  alignItems: "center",
  "@md": {
    flexDirection: "column",
    alignItems: "flex-start",
    flexBasis: "421px !important",
    flexGrow: 0,
    marginLeft: "80px",
  },
});
