import React from "react";
import { styled } from "../../../stitches.config";
import { Article, Category } from "../../types";
import { CategoriesDesktop } from "./CategoriesDesktop";
import { CategoriesMobileMenuTrigger } from "./CategoriesMobileMenuTrigger";

type Props = {
  isInRow?: boolean;
  latestArticles: Article[];
  categories: Category[];
};

export const Categories: React.FC<Props> = ({
  isInRow,
  categories,
  latestArticles,
}) => {
  return (
    <>
      <MobileWrap>
        <CategoriesMobileMenuTrigger categories={categories} />
      </MobileWrap>
      <DesktopWrap>
        <CategoriesDesktop
          latestArticles={latestArticles}
          categories={categories}
          isInRow={isInRow}
        />
      </DesktopWrap>
    </>
  );
};

const MobileWrap = styled("div", {
  "@md": {
    display: "none",
  },
});

const DesktopWrap = styled("div", {
  display: "none",
  "@md": {
    display: "block",
    width: "100%",
  },
});
