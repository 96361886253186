import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { ArticlesList } from "../../components/News/ArticlesList";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../layout";
import { routes } from "../../routes/routes";
import { Article } from "../../types";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { useScreenResolution } from "../../hooks/useScreenResolution";
import { Pagination } from "../../components/News/Pagination";
import { Loader } from "../../components/shared/Loader";
import { Button } from "../../components/shared/Button";
import { SearchBar } from "../../components/News/SearchBar";
import { getPageSize, getSlug } from "../../utils";
import { PageHead } from "../../components/shared/PageHead";
import { useScrollToElement } from "../../hooks/useScrollToElement";

export const NewsCategoryView = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [category, setCategory] = useState("");
  const [articles, setArticles] = useState<Article[]>([]);
  const [page, setPage] = useState({ currentPage: 1, totalPages: 1 });
  const [load, setLoad] = useState({
    isLoading: false,
    finished: false,
  });
  const resolution = useScreenResolution();
  const categorySlug = getSlug();
  const pageSize = getPageSize(resolution.resolution);
  const { scrollToElement, ref } = useScrollToElement({desktopOnly: true});

  useEffect(() => {
    const getData = async () => {
      setLoad((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await sendRequestToAPI(
          `
       query($category: String, $locale: I18NLocaleCode, $page: Int, $pageSize: Int){
         articles(locale: $locale,filters: {category:{categoryId:{eq: $category}}}, 
            pagination:{pageSize: $pageSize, page: $page}, sort:"publicationDate:desc"){
                meta{pagination{
                     total
                     page
                     pageSize
                     pageCount}}
                     data{
                 attributes{
                   previewImage{data{attributes{url}}}
                   title                   
                   publicationDate
                   slug
                   category{
                     data{
                       attributes{
                         categoryId
                         categoryName
                       }
                     }
                   }
                   description
                 }
                }
             }
             newsCategories(locale: $locale, filters: {categoryId: {eq: $category}}, sort:"categoryName:asc"){
               data{
                 attributes{
                   categoryId
                   categoryName
                 }
               }
             }
           }
        `,
          {
            category: categorySlug,
            locale: language,
            pageSize,
            page: page.currentPage,
          }
        );
        setLoad((prevState) => {
          const finished =
            page.currentPage === data.data.articles.meta.pagination.pageCount
              ? { finished: true }
              : {};
          return { ...prevState, isLoading: false, ...finished };
        });
        setArticles((prevState) => [
          ...(resolution.resolution === "desktop"
            ? [...data.data.articles.data]
            : [...prevState, ...data.data.articles.data]),
        ]);
        setCategory(data.data.newsCategories.data[0].attributes.categoryName);
        setPage((prevState) => ({
          ...prevState,
          totalPages: data.data.articles.meta.pagination.pageCount,
        }));
      } catch (err) {
        return err;
      }
    };
    categorySlug && pageSize && getData();
  }, [categorySlug, page.currentPage, pageSize]);

  return (
    <Layout>
      <PageHead title={category} />
      <ArticlesSection>
        <NavStripe>
          <NavStripeContent>
            {!!category && (
              <BreadCrumbsWrapper>
                <BreadCrumbs
                  showOnMobile={true}
                  root={{ link: routes.news.news, title: t("newsHeading") }}
                  current={category}
                />
              </BreadCrumbsWrapper>
            )}
            <CategoryHeading ref={(interalRef) => (ref.current = interalRef)}>
              {category}
            </CategoryHeading>
          </NavStripeContent>
        </NavStripe>
        <ContentContainer>
          <SearchBar />
          <NewsContainer>
            {!load.isLoading ? (
              <>
                <ArticlesList articles={articles} />
                {!!articles.length && (
                  <PaginationWrap>
                    {!load.finished &&
                      (load.isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          css={{
                            "@md": {
                              display: "none",
                            },
                          }}
                          onClick={() =>
                            setPage((prevState) => ({
                              ...prevState,
                              currentPage: prevState.currentPage + 1,
                            }))
                          }
                        >
                          {t("loadMore")}
                        </Button>
                      ))}
                    <Pagination
                      currentPage={page.currentPage}
                      totalPages={page.totalPages}
                      increase={() => {
                        setPage((prevState) => ({
                          ...prevState,
                          currentPage: prevState.currentPage + 1,
                        }));
                       scrollToElement();
                      }}
                      decrease={() => {
                        setPage((prevState) => ({
                          ...prevState,
                          currentPage: prevState.currentPage - 1,
                        }));
                        scrollToElement();
                      }}
                    />
                  </PaginationWrap>
                )}
              </>
            ) : (
              <Loader size={"xl"} />
            )}
          </NewsContainer>
        </ContentContainer>
      </ArticlesSection>
    </Layout>
  );
};

const ArticlesSection = styled("section", {});

const NavStripe = styled("div", {
  backgroundColor: "$white",
  "@md": {
    backgroundColor: "$primaryBackground",
    padding: "40px 0px",
  },
});

const NavStripeContent = styled("div", {
  maxWidth: 1280,
  display: "flex",
  flexDirection: "column-reverse",
  "@md": {
    margin: "0 auto",
    flexDirection: "column",
  },
});

const CategoryHeading = styled("h1", {
  color: "$heading",
  fontSize: "32px",
  lineHeight: "40px",
  textAlign: "center",
  margin: "0px 0px 24px 0px",
  padding: "40px 16px",
  backgroundColor: "$primaryBackground",
  "@md": {
    margin: "0px",
    padding: 0,
    textAlign: "start",
    fontSize: "48px",
    lineHeight: "56px",
  },
});

const BreadCrumbsWrapper = styled("div", {
  marginLeft: "16px",
  "@md": {
    marginLeft: 0,
  },
});

const ContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "60px 16px 80px 16px",
  "@md": {
    flexDirection: "row-reverse",
    padding: "80px 0px 100px 0px",
    maxWidth: 1280,
    margin: "0 auto",
  },
});

const NewsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexBasis: "calc(100% - 501px)",
});

const PaginationWrap = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "16px",
  "@md": {
    marginTop: "36px",
  },
});
