import { useCallback, useRef } from "react";
import { isBrowser } from "../components/shared/LiveSupport";
import { useScreenResolution } from "./useScreenResolution";

type Props = {
  desktopOnly?: boolean
}

export const useScrollToElement = ({desktopOnly} :Props) => {
  const ref = useRef<HTMLElement | null>(null);

  const {resolution} = useScreenResolution();

  const scroll = useCallback(() => { 
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  const scrollToElement = () => {
    if(desktopOnly){
     resolution !== 'mobile' && scroll()
    }else{
      scroll()
    }
  };

  return { scrollToElement, ref };
};
