import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "../../../stitches.config";
import SearchIcon from "../../../static/icons/common/search.svg";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { CSS } from "@stitches/react";

type Props = {
  type?: string;
};

export const SearchInput: React.FC<Props> = ({ type }) => {
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const onPressEnter = (e: KeyboardEvent) => {
    e.key === "Enter" && SubmitInput();
  };

  const SubmitInput = () => {
    navigate(`/news/search?q=${value}`);
  };

  useEffect(() => {
    window.addEventListener("keydown", onPressEnter);
    return () => window.removeEventListener("keydown", onPressEnter);
  });

  const css = useMemo<CSS>(() => {
    if (!ref.current?.clientWidth) {
      return {};
    }
    const width = ref.current?.clientWidth;
    if (!!value) {
      return {
        transform: `translateX(${width - 48}px)`,
        pointerEvents: "auto",
      };
    }

    return active && !value
      ? {
          transform: `translateX(${width - 48}px)`,
          pointerEvents: "auto",
        }
      : { pointerEvents: "none" };
  }, [active, value]);

  return (
    <InputWrapper
      type={type === "gray"}
      ref={(interalRef) => {
        ref.current = interalRef;
      }}
    >
      {!value && <Placeholder active={active}>{t("search")}</Placeholder>}
      <IconWrap onClick={() => SubmitInput()} css={css}>
        <SearchIcon />
      </IconWrap>
      <Input
        type={type === "gray"}
        active={active}
        onFocus={() => setActive(true)}
        onBlur={(e) => setActive(false)}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled("div", {
  border: "2px solid $accent",
  borderRadius: "8px",
  position: "relative",
  height: "48px",
  maxHeight: "48px",
  flexGrow: 2,
  "@md": {
    width: "100%",
    marginBottom: "34px",
  },

  variants: {
    type: {
      true: {
        marginBottom: 0,
        border: "1px solid $inputColor",
        borderRadius: "200px",
        flexGrow: 1,
        "@md": {
          width: "400px",
          margin: "30px 0",
        },
      },
    },
  },
});

const Input = styled("input", {
  width: "100%",
  height: "100%",
  padding: "12px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  fontSize: "17px",
  color: "$text",
  borderRadius: "8px",
  variants: {
    active: {
      true: {
        paddingLeft: "12px",
      },
    },
    type: {
      true: {
        borderRadius: "200px",
      },
    },
  },
});
const IconWrap = styled("span", {
  position: "absolute",
  fill: "$gray",
  top: 0,
  transition: "all ease-in-out 300ms",
  left: 4,
  cursor: "pointer",
  padding: "12px",
  height: "100%",
  pointerEvents: "none",
  "&:hover": {
    "& > svg": {
      fill: "$accent",
    },
  },
});

const Placeholder = styled("span", {
  position: "absolute",
  color: "$gray",
  fontWeight: 400,
  lineHeight: "24px",
  fontSize: "17px",
  top: 11,
  left: 12,
  pointerEvents: "none",
  transition: "all ease-in-out 300ms",
  transform: "translateX(36px)",
  variants: {
    active: {
      true: {
        transform: "translateX(0px)",
      },
    },
  },
});
