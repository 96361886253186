import React from "react";
import { styled } from "../../../stitches.config";
import Prev from "../../../static/icons/common/prev.svg";
import Next from "../../../static/icons/common/next.svg";

type Props = {
  totalPages: number;
  currentPage: number;
  increase: () => void;
  decrease: () => void;
};
export const Pagination = ({
  totalPages,
  currentPage,
  increase,
  decrease,
}: Props) => {
  return (
    <Navigation>
      <StyledButton locked={currentPage <= 1} onClick={decrease}>
        <Prev />
      </StyledButton>
      <PaginationCount>{`${currentPage}/${totalPages}`}</PaginationCount>
      <StyledButton locked={currentPage === totalPages} onClick={increase}>
        <Next />
      </StyledButton>
    </Navigation>
  );
};

const StyledButton = styled("button", {
  backgroundColor: "transparent",
  display: "block",
  width: "24px",
  height: "24px",
  border: "1px solid $text",
  borderRadius: "50%",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "& > svg": {
    fill: "$text",
    margin: "2px 2px 2px 1px",
  },
  "&:hover": {
    border: "1px solid $accent",
    "& > svg": {
      fill: "$accent",
    },
  },
  variants: {
    locked: {
      true: {
        pointerEvents: "none",
        border: "1px solid $footer",
        borderRadius: "50%",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "& > svg": {
          fill: "$footer",
          margin: "2px 2px 2px 1px",
        },
        "&:hover": {
          border: "1px solid $footer",
          "& > svg": {
            fill: "$footer",
          },
        },
      },
    },
  },
});

const PaginationCount = styled("span", {
  color: "$text",
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "24px",
  display: "inline-block",
  margin: "0 40px",
});
const Navigation = styled("div", {
  display: "none",
  "@md": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 auto",
    alignItems: "center",
    width: "153px",
    userSelect: "none",
    backgroundColor: "transparent",
  },
});
